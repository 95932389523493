body {
  // background-image: url("assets/images/default-bg.jpg");
  // background-size: cover;
  // background-attachment: fixed;
  // background-repeat: no-repeat;
}

img.ng-lazyloaded {
  animation: fadein .5s;
}
@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.lazy-bg {
  transition: opacity 0.5s ease-in;
}

body, .bg-dark {
  background-color: $sbBlack !important;
  color: $sbWhite;
}

.translucent-bg {
  background: rgba(0, 0, 0, 0.8);
}
.carousel-control-next, .carousel-control-prev {
  width: 5%;
}

.dotted-border-left {
  border-left: dotted $sbRed 4px;
}

.shows-filter {
  .filter-label {
    padding: 7px 1em 0 1em;
  }
}

.overlay-box {
  position: absolute;
  background-color: $sbRed;
  color: $sbWhite;
  font-size: 20px;
  padding: 4px 8px;
}

.tooltip {
  // background-color: $sbRed !important;
  .tooltip-arrow {
  // background-color: $sbRed !important;
  }
  div {
    color: $sbBlack !important;
  }
}

.align-right {
  text-align: right;
}

.speaker-chair-icons {
  text-align: left;
  img {
    margin: 0 8px 1em;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.desktop-only {
  display: none;
  @include media-breakpoint-up(lg) {
    display: inline-block;
  }
}

.tablet-only {
  display: none;
  @include media-breakpoint-up(md) {
    display: inline-block;
  }
}

.mobile-and-tablet {
  display: inline-block;
  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.mobile-only {
  display: inline-block;
  @include media-breakpoint-up(md) {
    display: none;
  }
}

header {
  // border-bottom: dotted $sbRed 4px;
}


footer {
  background: $sbBlack;
  position: relative;
  z-index: 1;
  border-top: dotted $sbRed 4px;
  padding-top: 2em;
  margin-top: 2em;
  padding-bottom: 2em;
}

.sidebar {
  border-left: none;
  border-top: dotted $sbRed 4px;
  padding-top: 2em;
  margin-top: 2em;
  @include media-breakpoint-up(xl) {
    border-top: none;
    border-left: dotted $sbRed 4px;
    padding-top: 0;
    margin-top: 0;
  }
}

.featured-copy {
  width: 60%;
  margin: 0 auto;
  text-align: center;
  margin-top: 0;
  @include media-breakpoint-down(md) {
    .h1 {
      font-size: 2rem;
    }
  }
  @include media-breakpoint-up(md) {
    margin-top: 2em;
  }
  @include media-breakpoint-up(lg) {
    margin-top: 6em;
  }
  @include media-breakpoint-up(xl) {
    margin-top: 6em;
  }
  a.btn {
    margin: 1em 0.3em;
  }
}

.featured-show .card {
  padding-right: 1em;
}


#shitty-hero {
  background-size: cover;
  height: 250px;
  @include media-breakpoint-up(md) {
    height: 350px;
  }
  @include media-breakpoint-up(lg) {
    height: 500px;
  }
  @include media-breakpoint-up(xl) {
    height: 500px;
  }
  position: relative;
}
#shitty-logo {
  background: #000;
  width: 100%;
  img {
    width: 70%;
    margin: 0 auto;
  }
}


.accordion {
  .openCloseIcon {
    width: 25px;
    display: inline-block;
  }
  .card-header {
    margin: 0;
    padding: 0;
    .btn-link {
      padding: 0 !important;
      text-align: left;
      &:hover, &:focus {
        text-decoration: none;
      }
    }
    .btn-link.collapsed {
      color: $sbGray;
      .openCloseIcon {
        color: inherit;
      }
      &:hover {
        color: $sbWhite;
      }
    }

    .btn-link.expanded {
      color: $sbWhite;
    }
  }
  .card-body {
    padding: 0.5em 2em 2em;
    font-size: 18px;
  }
}

.dropdown-item.active, .dropdown-item:active {
  background-color: $sbGray !important;
}


.modal {
  h1, h2, h3, h4, h5, h6, p, span, div {
    color: $sbBlack !important;
  }
}

.image-link img {
  &:hover {
    opacity: 0.6;
  }
}

// .simple-page {
//   padding: 2em 4em;
// }

.badge-danger {
  background-color: $sbRed;
}

.row img {
  max-width: 100%;
}

.staff-image {
  width: 100%;
  height: 350px;
  background-size: cover !important;
  background-position: center !important;
}

.dotted-line {
  border-bottom: dotted #d02217 4px;
  width: 100%;
  margin-bottom: 1em;
}