@font-face {
  font-family: 'bebas_kairegular';
  src: url('../assets/fonts/bebaskai-webfont.woff2') format('woff2'),
       url('../assets/fonts/bebaskai-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

h1, h2, h3, h4, h5, h6, p, span, div {
  color: $sbWhite;
}

body {
  // font-family: 'bebas_kairegular';
  // font-size: 1.3em;
}

// p, ul, ul li {
//   font-family: Arial, Helvetica, sans-serif;
//   font-size: 1em;
// }

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, .nav-item, .btn, .filter-label, label, .sb-font {
  font-family: 'bebas_kairegular';
  // font-size: 1.3em;
}

h5, h6, .h5, .h6 {
  font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  button {
    font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }
}

.nav-item, .filter-label, .btn {
  font-size: 1.3em;
}

a {
  color: $sbRed;
  &:hover {
    color: $sbRedDarker;
  }
}

.btn-primary, .btn-primary:active, .btn-primary:focus, .nav-pills .nav-link.active {
  background-color: $sbRed !important;
  border-color: $sbRed !important;
  color: $sbWhite !important;
  box-shadow: none !important;
  &:hover {
    background-color: $sbRedDarker !important;
    border-color: $sbRedDarker !important;
    color: $sbWhite !important;
  }
}

.nav-pills .nav-link {
  cursor: pointer;
}

.dropdown-menu {
  font-size: 20px;
}

.text-muted {
  color: $sbGray !important;
}

.white-anchor {
  color: $sbWhite;
  text-decoration: none;
  &:hover {
    color: $sbGray;
    text-decoration: none;
  }
}

.content {
  font-size: 18px;
}